import React from "react";
import { Box, styled, Typography, Link } from "@mui/material";

const Footer = () => {
	// const StackColumn = styled(Stack)(() => ({
	// 	flexDirection: "column",
	// 	justifyContent: "flex-start",
	// 	alignItems: "center",
	// 	flex: 1,
	// 	gap: 8,
	// 	textAlign: "center",
	// }));

	const BoxRow = styled(Box)(({ theme }) => ({
		display: "flex",
		flexDirection: "row",
		backgroundColor: "orange",
		flex: 1,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			gap: 30,
		},
	}));

	return (
		<BoxRow
			component="footer"
			sx={{
				py: 4,
				px: 2,
			}}
			color="white"
		>
			<Typography variant="caption" component="p">
				&copy; 2024 Cobble Horizons, Hosted and programmed by &copy;{" "}
				<Link
					href="https://kamikode.com"
					target="_blank"
					rel="noopener noreferrer"
					sx={{ color: "inherit", textDecoration: "underline" }}
				>
					Kamikode
				</Link>
			</Typography>
		</BoxRow>
	);
};

export default Footer;
