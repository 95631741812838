import React from "react";
import { Box, Button, styled, Typography, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import logo from "../assets/logo.png";

const Header = () => {
	const CustomBox = styled(Box)(({ theme }) => ({
		minHeight: "80vh",
		maxWidth: "100%",
		display: "flex",
		justifyContent: "center",
		gap: theme.spacing(2),
		paddingTop: theme.spacing(10),
		backgroundColor: "orange",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			alignItems: "center",
			textAlign: "center",
		},
	}));

	const BoxText = styled(Box)(({ theme }) => ({
		flex: "1",
		maxWidth: "100%",
		paddingLeft: theme.spacing(8),
		[theme.breakpoints.down("md")]: {
			flex: "2",
			textAlign: "center",
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	}));

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText("cobblehorizons.minecraft.best");
		alert("Copied to clipboard: cobblehorizons.minecraft.best");
	};

	return (
		<CustomBox component="header" sx={{ maxWidth: "100%", overflow: "hidden" }}>
			<BoxText
				component="section"
				sx={{ maxWidth: "100%", overflow: "hidden" }}
			>
				<Typography
					variant="h2"
					component="h1"
					sx={{
						fontWeight: 700,
						color: "#fff",
						maxWidth: "100%",
					}}
				>
					Discover the Horizons of Cobblemon!
				</Typography>

				<Typography
					variant="p"
					component="p"
					sx={{
						py: 3,
						lineHeight: 1.6,
						color: "#fff",
						fontSize: "30px",
						maxWidth: "100%",
						overflow: "hidden",
					}}
				>
					join us now!
				</Typography>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
						cursor: "pointer",
						justifyContent: {
							xs: "center",
							sm: "center",
							md: "start",
						},
					}}
					onClick={handleCopyToClipboard}
				>
					<Typography
						variant="p"
						component="p"
						sx={{
							lineHeight: 1.6,
							color: "#000000",
							fontWeight: "bold",
							fontSize: {
								xs: "20px", // Match icon size for mobile devices
								sm: "20px", // Match icon size for tablets
								md: "25px", // Match icon size for desktops
							},
							mb: "30px",
							maxWidth: "100%",
							paddingLeft: {
								xs: "28px", // Match icon size for mobile devices
								sm: "0px", // Match icon size for tablets
								md: "0px", // Match icon size for desktops
							},
						}}
					>
						cobblehorizons.minecraft.best
					</Typography>
					<IconButton
						aria-label="copy"
						color="primary"
						sx={{
							color: "#000000",
							"& .MuiSvgIcon-root": {
								fontSize: {
									xs: "15px", // Small size for mobile devices
									sm: "24px", // Default size for tablets and up
									md: "28px", // Slightly larger for desktops
								},
							},
						}}
					>
						<ContentCopyIcon />
					</IconButton>
				</Box>

				<Box>
					<a
						href="https://discord.gg/6Cq2ecRyt5"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button
							variant="contained"
							sx={{
								mt: 2,
								mr: 2,
								px: 4,
								py: 1,
								fontSize: "0.9rem",
								textTransform: "capitalize",
								borderRadius: 5,
								borderColor: "#7289da",
								color: "#fff",
								backgroundColor: "#7289da",
								"&&:hover": {
									backgroundColor: "#343a55",
								},
								"&&:focus": {
									backgroundColor: "#343a55",
								},
							}}
						>
							Join us on Discord
						</Button>
					</a>
					<a
						href="https://www.curseforge.com/minecraft/modpacks/cobble-horizons"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button
							variant="contained"
							sx={{
								mt: 2,
								mr: 2,
								px: 4,
								py: 1,
								fontSize: "0.9rem",
								textTransform: "capitalize",
								borderRadius: 5,
								borderColor: "#f58020",
								color: "#fff",
								backgroundColor: "#23293b",
								"&&:hover": {
									backgroundColor: "#343a55",
								},
								"&&:focus": {
									backgroundColor: "#343a55",
								},
							}}
						>
							Download the Modpack
						</Button>
					</a>
				</Box>

				<Typography
					variant="p"
					component="p"
					sx={{
						lineHeight: 1.6,
						color: "#fff",
						fontWeight: "bold",
						mt: 20,
					}}
				>
					New sections coming soon...
				</Typography>
			</BoxText>

			<Box
				sx={(theme) => ({
					[theme.breakpoints.down("md")]: {
						flex: "1",
						paddingTop: "30px",
						alignSelf: "center",
					},
					[theme.breakpoints.up("md")]: {
						flex: "2",
						alignSelf: "flex-end",
						display: "flex",
						justifyContent: "flex-end",
					},
				})}
			>
				<img
					src={logo}
					alt="headerImg"
					style={{
						width: "70%",
						marginBottom: -15,
					}}
				/>
			</Box>
		</CustomBox>
	);
};

export default Header;
